$primary:       #1491FF !default;
$secondary:     #0C6BBE !default;
$white:         #fff !default;
$gray-100:      #f8f9fa !default;
$gray-200:      #e9ecef !default;
$gray-300:      #dee2e6 !default;
$gray-400:      #ced4da !default;
$gray-500:      #adb5bd !default;
$gray-600:      #6c757d !default;
$gray-700:      #495057 !default;
$gray-800:      #343a40 !default;
$gray-900:      #212529 !default;
$black:         #000 !default;

$theme-colors: (
    "new-color":     #cc00ff,
    "new-gradient":  #ff0055
) !default;

[class*="new-gradient"] {
    background-image: linear-gradient(90deg, rgba(26, 215, 249, 0) 0%, #18d9f9 100%);
}

$body-bg:                   $white !default;
$body-color:                $gray-900 !default;

$link-color:                $primary !default;
$link-decoration:           none !default;
$link-hover-color:          $secondary !default;
$link-hover-decoration:     underline !default;

$border-width:              1px !default;
$border-color:              #464646 !default;

$border-radius:             0 !default;
$border-radius-lg:          0 !default;
$border-radius-sm:          0 !default;

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700|Poppins:300,400,500,600,700&display=swap');

$font-family-base:          'Lato', sans-serif !default;
$headings-font-family:      'Poppins', sans-serif !default;
$display-font-family:       'Poppins', sans-serif !default;
$blockquote-font-family:    'Lato', sans-serif !default;
$navbar-brand-font-family:  'Lato', sans-serif !default;
$btn-font-family:           'Lato', sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-base:      400 !default;
$line-height-base:      1.5 !default;

$h1-font-size:      40px !default;
$h2-font-size:      32px !default;
$h3-font-size:      30px !default;
$h4-font-size:      26px !default;
$h5-font-size:      20px !default;
$h6-font-size:      16px !default;

$h1-font-weight:    700 !default;
$h2-font-weight:    700 !default;
$h3-font-weight:    700 !default;
$h4-font-weight:    600 !default;
$h5-font-weight:    600 !default;
$h6-font-weight:    600 !default;

$headings-margin-bottom:    1rem !default;
$headings-line-height:      1.2 !default;
$headings-letter-spacing:   0 !default;
$headings-color:            inherit !default;
$headings-text-transform:   initial !default;
$headings-font-style:       normal !default;

$display1-size:             72px !default;
$display2-size:             66px !default;
$display3-size:             60px !default;
$display4-size:             54px !default;

$display1-weight:           600 !default;
$display2-weight:           600 !default;
$display3-weight:           600 !default;
$display4-weight:           600 !default;
$display-line-height:       1.1 !default;
$display-text-transform:    initial !default;
$display-font-style:        normal !default;

$lead-font-size:        20px !default;
$lead-font-weight:      300 !default;
$lead-line-height:      1.5 !default;

$small-font-size:           14px !default;
$extra-small-font-size:     11px !default;
$small-line-height:         1.4 !default;

$blockquote-font-size:          18px !default;
$blockquote-font-style:         normal !default;
$blockquote-font-weight:        400 !default;
$blockquote-letter-spacing:     0 !default;
$blockquote-line-height:        1.6 !default;

$hr-border-color:       $border-color !default;
$hr-border-width:       1px !default;

$backdrop-color:        rgba($black, 0.75);

$label-font-size:           0.8rem;
$label-line-height:         1.2em;
$label-letter-spacing:      0;
$label-font-weight:         bold;
$label-text-transform:      initial;

$input-placeholder-color:       $gray-400;
$input-border-color:            $border-color;
$input-padding-y:               0.5rem;
$input-padding-x:               0.75rem;
$input-box-shadow:              none;
$input-border-width:            1px;
$input-border-radius:           $border-radius !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

/*
 * 	Navigation Top Level Items
 */

$nav-font-size:         16px;
$nav-font-weight:       bold;
$nav-font-style:        normal;
$nav-text-transform:    uppercase;
$nav-line-height:       1.4em;
$nav-letter-spacing:    0.14em;
$nav-color:             rgba($gray-200, 0.6);
$nav-margin:            0;
$nav-padding:           10px 15px;

$nav-hover-background:  transparent;
$nav-hover-color:       $gray-200;

$nav-current-color:     $gray-200;

/*
 * 	Navigation Drop Down Items
 */

$nav-dropdown-background:           #131313;
$nav-dropdown-border-radius:        $border-radius;
$nav-dropdown-border:               none;
$nav-dropdown-box-shadow:           $box-shadow;
$nav-dropdown-container-padding:    0;
$nav-dropdown-item-padding:         10px 10px;
$nav-dropdown-font-family:          $font-family-base;
$nav-dropdown-font-size:            14px;
$nav-dropdown-font-weight:          bold;
$nav-dropdown-font-style:           normal;
$nav-dropdown-text-transform:       uppercase;
$nav-dropdown-line-height:          1.4em;
$nav-dropdown-letter-spacing:       0.8px;
$nav-dropdown-color:                #ffffff;

$nav-dropdown-hover-background:     #0c6bbe;
$nav-dropdown-hover-color:          #ffffff;

/*
 * 	Mobile Navigation
 */

 $mobile-nav-font-size:             14px;
 $mobile-nav-line-height:           1.4em;
 $mobile-nav-padding:               10px 15px;

/*
 * 	Buttons
 */

$btn-padding-y:               15px !default;
$btn-padding-x:               45px !default;
$btn-font-size:               16px !default;
$btn-text-transform:          uppercase !default;
$btn-letter-spacing:          .80px !default;
$btn-line-height:             1.4em !default;
$btn-border-width:            1px !default;
$btn-font-weight:             bold !default;
$btn-box-shadow:              none !default;
$btn-focus-width:             1px !default;
$btn-focus-box-shadow:        none !default;
$btn-active-box-shadow:       none !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$yiq-contrasted-threshold:    175 !default;


$btn-padding-y-lg:            $btn-padding-y * 1.1 !default;
$btn-padding-x-lg:            $btn-padding-x * 1.1 !default;
$btn-font-size-lg:            $btn-font-size !default;

$btn-padding-y-sm:            $btn-padding-y * 0.9 !default;
$btn-padding-x-sm:            $btn-padding-x * 0.9 !default;
$btn-font-size-sm:            $btn-font-size !default;


/*
 * 	Badges
 */

$badge-padding-y:           0 !default;
$badge-padding-x:           0 !default;
$badge-font-size:           12px !default;
$badge-font-style:          normal !default;
$badge-color:               $gray-900 !default;
$badge-font-weight:         700 !default;
$badge-text-transform:      uppercase !default;
$badge-line-height:         1.4em !default;
$badge-letter-spacing:      0.1em !default;
$badge-border-radius:       0 !default;
$badge-background-color:    transparent !default;
$badge-border-width:        0 !default;
$badge-border-color:        transparent !default;

$badge-focus-width:         1px !default;



/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base:    theme-color("primary") !default;
$owl-color-white:   $white !default;
$owl-color-gray:    $gray-500 !default;

//nav

$owl-nav-color:             rgba($white, 0.75) !default;
$owl-nav-color-hover:       $white !default;
$owl-nav-font-size:         40px !default;
$owl-nav-rounded:           0 !default;
$owl-nav-margin:            0 10px !default;
$owl-nav-padding:           10px !default;
$owl-nav-background:        transparent !default;
$owl-nav-background-hover:  transparent !default;
$owl-nav-disabled-opacity:  0.5 !default;

//dots

$owl-dot-width:                 8px !default;
$owl-dot-height:                8px !default;
$owl-dot-rounded:               100% !default;
$owl-dot-margin:                5px 7px !default;
$owl-dot-background:            rgba($white, 0.75) !default;
$owl-dot-background-active:     $white !default;