/*
This is where you write custom SASS
*/
header {
    background-color: #131313 !important;

    .ph {
        color: rgba(255,255,255,0.7);
    }
    a:hover {
        color: rgba(255,255,255,1);
    }
}
.bg-g {
    background-color: #222222 !important;
}
.bg-cta {
    background-color: #131313;
    .btn {
        padding: 40px 0 20px 35px;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        letter-spacing: .80px;
        text-transform: uppercase;
        text-align: left;
    }
}
.bg-foot {
    background-color: #131313;
    margin: -7px 0 0 0;
    color: rgba(255,255,255,0.7);
    .mod {
        margin: 0 0 5px 0;
        br {
            display: none;
        }
        a {
            color: rgba(255,255,255,0.7);
        }
        a:hover {
            color: rgba(255,255,255,1);
        }
        p {
            color: rgba(255,255,255,0.7);
            margin: 0 0 5px 0;
        }
    }
}
.testimonials {
    .owl-nav button.owl-prev {
        display: none !important;
    }
    .owl-nav button.owl-next {
        display: none !important;
    }
}
.owl-carousel {
    .btn {
        font-size: 14px;
    }
}
.bg-test {
    background-image: url('/prositesStyles/StyleLibrary/6291/images/test-bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}
.tag-bg {
    background-image: url('/prositesStyles/StyleLibrary/6291/images/tag-bg.png');
    background-position: 30px top;
    background-repeat: no-repeat;
    width: 473px;
    height: 415px;
    .ban-border {
        width: 167px !important;
        height: 4px !important;
        margin-bottom: 50px;
    }
    p {
        margin-top: 75px;
        font-size: 80px;
        color: #434242;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 0 !important;
        font-family: 'Poppins', sans-serif;

        span {
            color: #434242;
            letter-spacing: -.20px;
            font-size: 40px !important;
            font-family: 'Poppins', sans-serif;
            font-weight: normal;
        }
    }
}
.navbar ul.navbar-nav > li > a {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4em;
    letter-spacing: .80px;
    color: rgba(233,236,239,0.6);
    text-transform: uppercase;
    margin: 0 15px !important;
    padding: 10px 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px solid #222;
}
.navbar ul.navbar-nav > li > a:hover {
    background: rgba(0,0,0,0) !important;
    color: #e9ecef !important;
    border-bottom: 5px solid #3DA2FB;
}
.navbar ul.navbar-nav a.current, .navbar ul.navbar-nav a.highlighted {
    color: #fff !important;
    border-bottom: 5px solid #3DA2FB;
}
.navbar ul.navbar-nav > li .dropdown-menu li a:hover, .navbar ul.navbar-nav > li .dropdown-menu li a.bg-light {
    border: none !important;
}
.navbar ul.navbar-nav > li .dropdown-menu li a {
    border: none !important;
}
.bg-b {
    background-color: #3DA2FB;
    margin: 196px 0 0 0
}
.clear {
    clear: both;
}
.pt-45 {
    padding-top: 45px;
}
.addr {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .80px;
    a {
        color: #fff;
        span {
            color: #3DA2FB;
        }
    }
    a:hover {
        color: rgba(255,255,255,0.7);
    }
    span {
        color: #3DA2FB;
    }
    .dc {
        color: #3DA2FB !important;
    }
    .dc:hover {
        color: #0C6BBE !important;
    }
}
.pl-75 {
    padding-left: 75px;
}
.main {
    .assoc {
        margin-right: 15px;
        margin-top: 20px;
    }
    h1 {
        margin-bottom: 25px;
        margin-top: 60px;
    }
    .title-border {
        margin-bottom: 45px;
    }
    .btn {
        margin-bottom: 45px;
    }
}
.services  {
    padding: 150px 100px 115px 395px;
    .fa-minus {
        background-color: #3DA2FB;
        padding: 5px;
        border-radius: 20px;
        width: 25px !important;
        height: 25px !important;
        font-size: 16px;
    }
    .fa-plus {
        background-color: #3DA2FB;
        padding: 5px;
        border-radius: 20px;
        width: 25px !important;
        height: 25px !important;
        font-size: 16px;
    }
    .h6 {
        text-transform: uppercase;
        font-weight: bold;
    }
    .title-serv {
        position: relative;
        top: -4px;
    }
    .mod1 {
        padding-left: 36px;
    }
    .btn {
        font-size: 14px;
    }
}
.testimonials {
    .wrap {
        padding: 150px 395px 115px 85px;
    }

    blockquote {
        margin-top: 20px;
        margin-left: 3px;
        font-size: 21px;
        font-style: italic;
    }
    .quotes {
        background-image: url('/prositesStyles/StyleLibrary/6291/images/quotes.png');
        background-repeat: no-repeat;
        width: 99px;
        height: 66px;
        position: absolute;
        top: 125px;
        left: 90px;
    }
    .btn {
        font-size: 14px;
    }
}
.contact {
    text-align: center;
    .form-group {
        margin-bottom: 7px !important;
        margin-top: 7px !important;
    }
    input {
        border: none;
    }
    .form-control {
        border: none;
    }
    .form-control:focus {
        box-shadow: none;
    }
    ::-webkit-input-placeholder {
        color: #919191;
        opacity: 1 !important; /* for older chrome versions. may no longer apply. */
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #919191;
        opacity: 1 !important;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: #919191;
        opacity: 1 !important;
    }
    :-ms-input-placeholder {  
       color: #919191;
    }
    h2 {
        margin-bottom: 0;
    }
    p {
        line-height: 20px;
        font-size: 18px;
        margin: 20px 0 25px 0;
    }
    .btn {
        margin-top: 15px;
        font-size: 15px;
    }
}
.bg-int-addr {
    background-color: #131313;
}
.navbar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
}
.navbar ul.navbar-nav > li .dropdown-menu li a.current, .navbar ul.navbar-nav > li .dropdown-menu li a.highlighted {
    background: #1491ff;
}
.form-group {
    label {
        display: none;
    }
    input {
        border: none;
    }
    .form-control {
        border: none;
    }
    .form-control:focus {
        box-shadow: none;
    }
    ::-webkit-input-placeholder {
        color: #919191;
        opacity: 1 !important; /* for older chrome versions. may no longer apply. */
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #919191;
        opacity: 1 !important;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: #919191;
        opacity: 1 !important;
    }
    :-ms-input-placeholder {  
       color: #919191;
    }
}
.form {
    label {
        display: none;
    }
    input {
        border: none;
    }
    .form-control {
        border: none;
    }
    .form-control:focus {
        box-shadow: none;
    }
    ::-webkit-input-placeholder {
        color: #919191;
        opacity: 1 !important; /* for older chrome versions. may no longer apply. */
    }
    :-moz-placeholder { /* Firefox 18- */
        color: #919191;
        opacity: 1 !important;
    }
    ::-moz-placeholder {  /* Firefox 19+ */
        color: #919191;
        opacity: 1 !important;
    }
    :-ms-input-placeholder {  
       color: #919191;
    }
}
.interior {
    .card {
        padding: 40px 35px;
        background-color: #1F1F1F;
        width: 372px;
        float: right;
        .form-group {
            margin-bottom: 7px;
            margin-top: 7px !important;
        }
        .h3 {
            margin-bottom: 10px;
            font-size: 34px;
        }
        .btn {
            margin-top: 5px;
        }
    }
}


@media (max-width: 1700px) {
    .testimonials {
        .wrap {
            padding: 150px 300px 115px 85px;
        }
    }
    .services  {
        padding: 150px 100px 115px 300px;
    }
}

@media (max-width: 1500px) {
    .testimonials {
        .wrap {
            padding: 150px 185px 115px 85px;
        }
    }
    .services  {
        padding: 150px 100px 115px 185px;
    }
}

@media (max-width: 1300px) {
    .testimonials {
        .wrap {
            padding: 150px 82px 115px 85px;
        }
    }
    .services  {
        padding: 150px 100px 115px 82px;
    }
}

@media (max-width: 1200px) {
    .bg-test {
        background-position: 80% top;
    }
    .addr {
        font-size: 17px;
        letter-spacing: -.3px;
    }
    .owl-carousel {
        .mod1 {
            background-position: 55% 20% !important;
        }
        .mod2 {
            background-position: 55% 20% !important;
        }
        .mod3 {
            background-position: 80% 20% !important;
        }
    }
}

@media (max-width: 992px) {
    .interior {
        .card {
            width: 100%;
            float: none;
        }
    }
    .navbar ul.navbar-nav > li > a {
        border-bottom: none;
    }
    .navbar ul.navbar-nav > li > a:hover {
        border-bottom: none;
    }
    .navbar ul.navbar-nav a.current, .navbar ul.navbar-nav a.highlighted {
        border-bottom: none;
    }
    .main {
        .mod1 {
            padding-left: 15px;
        }
    }
    .bg-foot {
        text-align: center;
        .mod {
            margin: 15px 0 0 0;
            text-align: center;
            font-size: 14px;
        }
    }
    .testimonials {
        .quotes {
            top: 33px;
            left: 82px;
        }
        .wrap {
            padding: 60px;
        }
    }
    .services {
        padding: 60px;
    }
    .bg-cta {
        .cta1 {
            width: 372px;
            margin: 0 auto;
        }
        .cta2 {
            width: 372px;
            margin: 0 auto;
        }
        .cta3 {
            width: 372px;
            margin: 0 auto;
        }
    }
    
    .owl-carousel {
        .mod1 {
            background-position: 63% 20% !important;
        }
        .mod2 {
            background-position: 70% 20% !important;
        }
        .mod3 {
            background-position: 90% 20% !important;
        }
    }
    .tag-bg {
        background: none;
        p {
            font-size: 45px;
            span {
                font-size: 21px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .testimonials {
        .quotes {
            top: 33px;
            left: 71px;
        }
        .wrap {
            padding: 60px 83px;
        }
    }
    .services {
        padding: 60px 100px;
    }
    .main {
        .pad {
            padding: 60px 0 !important;
        }
    }
    .bg-cta {
        padding-bottom: 60px !important;
    }
    .addr {
        font-size: 16px;
        letter-spacing: .80px;
    }
    .tag-bg {
        display: none;
    }
    .owl-carousel {
        .mod1 {
            background-position: 83% 20% !important;
        }
        .mod2 {
            background-position: 80% 20% !important;
        }
        .mod3 {
            background-position: 90% 20% !important;
        }
    }
}

@media (max-width: 576px) {
    .owl-carousel {
        .ht {
            height: 470px !important;
        }
    }
    .testimonials {
        .quotes {
            top: 33px;
            left: 15px;
        }
    }
    .testimonials {
        .wrap {
            padding: 60px 15px;
        }
    }
    .services {
        padding: 60px 15px;
    }
    .main {
        .pad {
            padding: 60px 15px !important;
        }
    }
    .owl-carousel {
        .mod1 {
            background-position: 75% 20% !important;
        }
        .mod2 {
            background-position: 75% 20% !important;
        }
    }
    .addr {
        font-size: 14px;
        letter-spacing: 0px;
    }
}
@media (max-width: 400px) {
    .bg-cta {
        .cta1 {
            width: 100%;
            margin: 0 auto;
        }
        .cta2 {
            width: 100%;
            margin: 0 auto;
        }
        .cta3 {
            width: 100%;
            margin: 0 auto;
        }
    }
}





